<template>
  <div class="about">
    <h2>About Autosaved</h2>
    <h3>Born Again Geeks</h3>

    <p>
      Just a couple of nerdy, Jesus-loving guys.
    </p>

    <p>
      We’ve been friends since age 12. We’ve written programs. We’ve worked for NASA.
      We’ve made bad web comics. We’ve written books. We once bashed in a computer with a hatchet.
      We once had a particular terrifying incident with a chainsaw (no one was hurt).
    </p>

    <p>
      All of those things are true except for one, but I bet you can't guess which.
    </p>

    <div class="row">
      <div class="col-6">
        <img src="@/assets/images/KrentzCircle.png" class="img-fluid" />
      </div>
      <div class="col-6">
        <img src="@/assets/images/RobCircle.png" class="img-fluid" />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <h3 class="text-uppercase text-center">Chris Krentz</h3>
      </div>
      <div class="col-6">
        <h3 class="text-uppercase text-center">Robbie Mahair</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <h4 class="text-uppercase text-center">Programmer & Certified Internet Expert</h4>
      </div>
      <div class="col-6">
        <h4 class="text-uppercase text-center">Programmer & Author</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <p class="text-justify">
          What is a man? A miserable little pile of secrets. Hi, I am Chris Krentz and my secrets are that I am a programmer, video gamer,
          guitar player, and husband… er. My nerd credentials include having played World of Warcraft since the beta version in 2004,
          building a cryptocurrency mining rig, having a large collection of Star Wars toys and memorabilia, playing and DM’ing multiple
          D&D groups, and helping to write Spritecraft (a little).
        </p>
      </div>
      <div class="col-6">
        <p class="text-justify">
          Hi! My name is Robbie Mahair. I’m a software developer (I tell the computer what to do), author (I sez fancy words and stuffs),
          father of four (so I’ve spent a tremendous amount of time changing diapers and, in general, dealing with other people’s urine
          and feces), and a husband of one (so I’ve also spent a lot of time apologizing).
        </p>
        <p class="text-justify">
          If you are interested in my books, you can find them <a href="https://www.amazon.com/Robbie-Mahair/e/B07FJMSNLM">on Amazon</a>.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="text-center" style="font-style: italic;">chris@autosaved.org</div>
      </div>
      <div class="col-6">
        <div class="text-center" style="font-style: italic;">robbie@autosaved.org</div>
      </div>
    </div>

  </div>
</template>
